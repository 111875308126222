<template>
    <div class="eventPage">
        <div class="layout">
            <div class="row">
                <div class="flex md6 lg4">
                <va-card>
                    <va-image
                    :src="act.pic"
                    style="height: 200px;"
                    />
                    <va-card-title class="display-5">{{act.title}}</va-card-title>
                    <va-card-content class="event_content">
                        <p class="mb-2">
                            <va-icon :size="14">calendar_today</va-icon>
                            {{act.start_at}} ~ {{act.end_at}}
                        </p> 
                        {{act.description}}
                    </va-card-content>
                </va-card>
                </div>
            </div>
        </div>
        <va-button @click="register" :rounded="false" gradient class="registerBtn" :disabled="rgstDisabled">
            <p>立即報名</p>
        </va-button>
    </div>
</template>
<script>
import { mapGetters,mapMutations,mapActions } from "vuex"
export default {
    data(){
        return{
            // act:{
            //     title: 'Skyline Film 屋頂電影院 5月台北放映',
            //     pic: 'https://i.pinimg.com/564x/4c/5a/18/4c5a185d559021dad2b8eb5e9243d5dc.jpg',
            //     start_at: '2022-05-01 00:00',
            //     end_at: '2022-05-20 23:50',
            //     description: '厭倦了逆來順受的人生，要活出屬於自己的樣子，需要勇氣，也需要運氣。 5月的台北及高雄屋頂，同步帶來4部經典好片，有點叛逆，有點淒美，也有點天真的浪漫。 從朋友變情人，甜在心的是婚禮歌手與女服務生間的曖昧火花。 從商場敵人變情人，發自內心的愛什麼也擋不住。 埋藏許久的秘密，四天的短暫愛情卻像是永恆。 沒得選擇的選擇，代價會是放逐。'
            // },
            act:{
                title: 'Yacht Club 開幕Party',
                pic: '/img/yacht-club.jpg',
                start_at: '2022-05-01 00:00',
                end_at: '2022-05-20 23:50',
                description: '厭倦了逆來順受的人生，要活出屬於自己的樣子，需要勇氣，也需要運氣。 5月的台北及高雄屋頂，同步帶來4部經典好片，有點叛逆，有點淒美，也有點天真的浪漫。 從朋友變情人，甜在心的是婚禮歌手與女服務生間的曖昧火花。 從商場敵人變情人，發自內心的愛什麼也擋不住。 埋藏許久的秘密，四天的短暫愛情卻像是永恆。 沒得選擇的選擇，代價會是放逐。'
            },
            rgstDisabled: true,
        }
    },
    computed:{
        ...mapGetters([
            'nftAmt'
        ])
    },
    async mounted(){
        await this.getNFTremain()
        this.rgstDisabled = false
    },
    methods:{
        ...mapMutations([
            'setErrorMsg'
        ]),
        ...mapActions([
            'getNFTremain'
        ]),
        register(){
            console.log(this.nftAmt);
            if(this.nftAmt == 0){
                this.setErrorMsg({
                    status: true, 
                    type: "normal", 
                    title: "報名失敗", 
                    content: "您的NFT不足或已兌換完畢。", 
                })
                return
            }
            this.setErrorMsg({
                status: true, 
                type: "register",
                title: "報名確認",
                content: `確定報名 ${this.act.title} 此項活動嗎？`,
            })
        },
    }
}
</script>
<style scoped lang='scss'>
@import "../assets/main.scss";
.eventPage{
    margin-top: 44px;
    margin-bottom: 60px;
    .registerBtn{
        width: 100%;
        height: 52px;
        position: fixed;
        bottom: 0;
        text-align: center;
        p{
            font-weight: bold;
            color: #fff;
            line-height: 60px;
            letter-spacing: 4px;
        }
    }
    .event_content{
        font-size: 15px;
        p{
            font-size: 14px;
            color: #777;
        }
    }
}
</style>